import { Flexbox, Grid } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import PageTitle from '@src/components/PageTitle'
import PageText from '@src/components/PageText'

const S03About = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox column {...props}>
      <PageTitle label="Продукт" title="Redsk AI" />
      <Grid gap={['4rem', '2rem']} templateColumns={['1fr 1fr', '1fr']}>
        <PageText>
          Комплексная система автоматизации <br />
          предприятий-поставщиков HoReCa с глубоко <br />
          продуманными алгоритмами позволяющими <br />
          увеличить прибыль, снизить время <br />
          обработки заказов, повысить клиентоориентированность и <br />
          значительно улучшить имидж компании.
        </PageText>
        <PageText>
          Система не требует больших <br />
          финансовых вложений и может быть <br />
          с легкостью внедрена как в небольшие <br />
          так и в устоявшиеся компании <br />
          с большим количеством ежедневных <br />
          заказов.
        </PageText>
      </Grid>
    </Flexbox>
  )
}

export default S03About
